import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpRequest} from '@angular/common/http';
import {map, switchMap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {StorageService} from './storage.service';
import {DOMAIN, WEIGHT_KEY, WORKOUT_KEY} from './authentication.service';

@Injectable()
export class RequestService {

  constructor(private http: HttpClient,
              private storage: StorageService) {
  }

  weights(user) {
    return this.http.get(`${DOMAIN}weights?user.username=${user}`).pipe(
      map((data: any) => data),
      switchMap(data => this.storage.set(WEIGHT_KEY, data))
    );
  }

  weightUpdate(weightID, size) {
    const body = {size};
    return this.http.put(`${DOMAIN}weights/${weightID}`, body);
  }

  weightCreate(exerciseID, size, userID) {
    const body = {
      size,
      exercise:  exerciseID,
      user: userID
    };
    return this.http.post(`${DOMAIN}weights`, body);
  }

  exerciseExecuteUpdate(weightID, note?) {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const body = {execute_in: new Date(), note};
    return this.http.put(`${DOMAIN}weights/${weightID}`, body);
  }

  userUpdate(id, body) {
    return this.http.put(`${DOMAIN}users/${id}`, body);
  }

  userProfile(id) {
    return this.http.get(`${DOMAIN}users/${id}`);
  }

  uploadUserImage(formData) {
    const requestOptions = {
      method: 'POST',
      body: formData,
      reportProgress: true
    };
    return fetch(`${DOMAIN}upload`, requestOptions);
  }

  deleteUserImage(id) {
    return this.http.delete(`${DOMAIN}upload/files/${id}`);
  }

  sendFeedback(body) {
    return this.http.post(`${DOMAIN}feedbacks`, body);
  }

  workouts(user): Observable<any> {
    return this.http.get(`${DOMAIN}workouts?users.username=${user}`).pipe(
      map((data: any) => data),
      switchMap(data => this.storage.set(WORKOUT_KEY, data))
    );
  }
}

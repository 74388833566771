import {Injectable} from '@angular/core';
import {Storage} from '@ionic/storage-angular';
import {NativeStorage} from '@ionic-native/native-storage/ngx';
import {Platform} from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private $storage: Storage | null = null;

  constructor(private nativeStorage: NativeStorage,
              private storage: Storage,
              public platform: Platform) {
    // alert(platform.platforms().toString());
    this.init();
  }

  async init() {
    this.$storage = await this.storage.create();
  }

  public async set(key: string, value: any) {
    // window.localStorage.setItem(key, JSON.stringify(value));
    // alert('STORAGE SET ' + window.localStorage.getItem(key));
    await this.storage.create();
    return this.storage?.set(key, value);
  }

  public async get(key: string): Promise<any> {
    await this.storage.create();
    return this.storage?.get(key);
  }

  public async remove(key: string): Promise<any> {
    await this.storage.create();
    return this.storage?.remove(key);
  }

  public async clearAll(): Promise<any> {
    await this.storage.create();
    return this.storage?.clear();
  }


}

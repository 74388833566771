import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map, switchMap, tap} from 'rxjs/operators';
import {BehaviorSubject, Observable} from 'rxjs';
import {StorageService} from './storage.service';
import {Router} from '@angular/router';
import {SafeResourceUrl} from '@angular/platform-browser';

export const TOKEN_KEY = 'my-token';
export const USER_KEY = 'user-local';
export const WORKOUT_KEY = 'workout-local';
export const WEIGHT_KEY = 'weight-local';
export const DOMAIN: SafeResourceUrl = 'https://nerdbrain.it/workout/api/';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  token = '';

  constructor(private http: HttpClient,
              private router: Router,
              private storage: StorageService) {
    this.loadToken(true);
  }

  public getToken(): string {
    return this.token;
  }

  async loadToken(isFirst=false) {
    const token = await this.storage.get(TOKEN_KEY);
    console.log('token',isFirst, token);
    if (token) {
      this.token = token;
      this.isAuthenticated.next(true);
    } else {
      console.log('No token gets ');
      this.logout();
    }

    return token;
  }

  login(credentials: { identifier; password }): Observable<any> {
    return this.http.post(`${DOMAIN}auth/local`, credentials).pipe(
      map((data: any) => data),
      switchMap(data => {
        console.log('LOGIN SUCCES =>', data);
        this.storage.set(USER_KEY, data.user);
        return this.storage.set(TOKEN_KEY, data.jwt);
      }),
      tap(_ => {
        this.isAuthenticated.next(true);
      })
    );
  }

  logout(): Promise<void> {
    this.isAuthenticated.next(false);
    this.storage.clearAll();
    this.token='';
    this.router.navigateByUrl('/login', { replaceUrl: true });
    return;
  }
}

import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthenticationService} from './authentication.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  token = '';

  constructor(public auth: AuthenticationService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.token = this.auth.getToken();
    if(request.url.includes('/upload')){
      console.log('IS UPLOAD');
      request = request.clone({
        setHeaders: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'Content-Type': 'multipart/form-data',
          'Accept': 'application/json',
        }
      });
    }else{
      request = request.clone({
        setHeaders: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'Content-Type': 'application/json; charset=utf-8',
          'Accept': 'application/json',
        }
      });
    }

    if (this.token) {
      const authenticatedRequest = request.clone({
        headers: request.headers.set(
          'Authorization',
          `Bearer ${this.token}`
        )
      });
      // Request with authorization headers
      return next.handle(authenticatedRequest);
    } else {
      // Request without authorization header
      this.auth.loadToken();
      this.token = this.auth.getToken();
      if (this.token) {
        const authenticatedRequest = request.clone({
          headers: request.headers.set(
            'Authorization',
            `Bearer ${this.token}`
          )
        });
        // Request with authorization headers
        return next.handle(authenticatedRequest);
      } else {
        return next.handle(request);
      }
    }
  }
}

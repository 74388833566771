import {Component} from '@angular/core';
import {ModalController, Platform} from '@ionic/angular';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {AuthenticationService, USER_KEY} from './services/authentication.service';
import {ThemeService} from './theme.service';
import {StorageService} from './services/storage.service';
import {ModalFeedbackPage} from './pages/modal-feedback/modal-feedback.page';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  activePageTitle = 'Dashboard';
  activeIndex = 0;
  pages = [
    {
      title: 'Workouts',
      url: '/workout-list',
      icon: 'albums',
    },
    {
      title: 'Profilo',
      url: '/profile',
      icon: 'person',
    },
    {
      title: 'Logout',
      url: '/logout',
      icon: 'exit',
    }
  ];
  user: any;

  constructor(
    private platform: Platform,
    private statusBar: StatusBar,
    private splashScreen: SplashScreen,
    private authService: AuthenticationService,
    private storage: StorageService,
    public modalCtrl: ModalController,
    private themeService: ThemeService
  ) {
    this.loadProfile().then(user => {
      this.user = user;
      this.themeService.activeTheme(user.theme);
    });

    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  async loadProfile() {
    return await this.storage.get(USER_KEY);
  }

  async sendFeedback() {
    console.log('sendFeedback', this.user);
    const modal = await this.modalCtrl.create({
      component: ModalFeedbackPage,
      componentProps: {
        userID: this.user.id
      }
    });
    modal.onDidDismiss().then((obj) => {
      console.log('close',obj);
    });

    return await modal.present();
  }

  async logout() {
    await this.authService.logout();
  }

}

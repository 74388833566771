import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {LoadingController, ModalController} from '@ionic/angular';
import {RequestService} from '../../services/request.services';

@Component({
  selector: 'app-modal-feedback',
  templateUrl: './modal-feedback.page.html',
  styleUrls: ['./modal-feedback.page.scss'],
})
export class ModalFeedbackPage implements OnInit {
  @Input() userID: any;

  feedback: FormGroup;

  constructor(
    private modalCtr: ModalController,
    private request: RequestService,
    private fb: FormBuilder,
    private loadingController: LoadingController
  ) {
    this.feedback = this.fb.group({
      description: ['', [Validators.required, Validators.minLength(50)]],
      images:[''],
      user:this.userID
    });
  }

  ngOnInit() {
    console.log(this.userID);
    this.feedback.get('user').setValue(this.userID);

  }

  get description() {
    return this.feedback.get('description');
  }

  // async presentLoading() {
  //   const loading = await this.loadingController.create({
  //     message: 'Caricamento...',
  //   });
  //   await loading.present();
  //   return loading;
  // }
  //
  // async onFileChange(event: any, type: string) {
  //   event.stopPropagation();
  //   event.preventDefault();
  //
  //   const fileList = event.target.files;
  //   if (fileList && fileList.length) {
  //     const loading = await this.presentLoading();
  //
  //     const [file] = fileList;
  //     const formData = new FormData();
  //     formData.append('files', file, file.name);
  //     formData.append('refId', this.userID.toString());
  //     formData.append('field', type);
  //     formData.append('ref', 'feedback');
  //     // formData.append('source', 'users-permissions');
  //     this.request.uploadUserImage(formData)
  //       .then(response => response.text())
  //       .then(async (result) => {
  //         await loading.dismiss();
  //         const [image] = JSON.parse(result);
  //         this.feedback.get('images').setValue(image.formats.thumbnail.url);
  //       })
  //       .catch(async (error) => {
  //         await loading.dismiss();
  //         console.log('error', error);
  //       });
  //
  //   }
  // }

  deletePhoto(id){
    if(id){
      console.log(id);
      this.request.deleteUserImage(id).subscribe(
        async (res) => console.log(res),
        async (res) => console.log(res)
      );
    }
  }

  async close() {
    console.log(this.feedback.valid, this.feedback.value);
    if(this.feedback.valid){
      this.request.sendFeedback(this.feedback.value).subscribe(
        async (user) => {
          await this.modalCtr.dismiss(true);
        },
        async (err) => {
          console.log(err);
        }
      );
    }else{
      await this.modalCtr.dismiss(false);
    }
  }

}
